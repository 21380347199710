<template>
    <div v-show="irradiatorMainVisible">
        <el-form ref="queryForm" :label-position="labelPosition" label-width="80px" :model="queryForm" :rules="dateRangeRules" inline>
            <el-form-item prop="dateRange" label="时间范围">
                <el-date-picker
                    v-model="queryForm.dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    :picker-options="datePickerOptions"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button style="margin-left: 10px;" type="primary" @click="queryData()">查询</el-button>
            </el-form-item>
        </el-form>
        <div id="fzChartDataDiv" v-loading="loading" style="width:95%;height:580px;margin-top: 30px"></div>
    </div>
</template>

<script>
    import {queryIrradiatorChartData} from "../../../../api/station/deviceInfoApi";
    import message from "@/utils/message";
    let echarts = require('echarts');
    export default {
        name: "IrradiatorMain",
        props: {

            irradiatorMainVisible: {
                type: Boolean,
                default: false
            }
        },
        data() {

            // 校验用户选择日期范围不能超过5天
            let validateDateRange = (rule, value, callback) => {

                if (value && value.length === 2) {

                    let startDate = new Date(value[0]);
                    let endDate = new Date(value[1]);
                    let fiveDaysAfterStart = new Date(startDate);
                    fiveDaysAfterStart.setDate(startDate.getDate() + 4);
                    if (endDate.getTime() > fiveDaysAfterStart.getTime()) {

                        callback(new Error('日期范围不能超过五天'));
                    } else {

                        callback();
                    }
                } else {

                    callback(new Error('请选择开始日期与结束日期'));
                }
            };
            return {

                labelPosition: 'left',
                deviceNum: "",
                devicePartsId: null,
                queryForm: {

                    dateRange: []
                },
                datePickerOptions: {

                    disabledDate(time) {

                        return time.getTime() > Date.now();
                    }
                },
                dateRangeRules: {

                    dateRange: [{ validator: validateDateRange, trigger: 'change' }]
                },
                chartDataOption: {},
                chartNoDataOption: {},
                loading: false
            }
        },
        methods : {

            // 初始化页面数据
            initPage(deviceNum, devicePartsId) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                this.deviceNum = deviceNum;
                this.devicePartsId = devicePartsId;
                let startDate = new Date();
                let endDate = new Date();
                startDate.setDate(endDate.getDate());
                this.queryForm.dateRange = [startDate, endDate];
                this.initChartDataOption();
                this.queryIrradiatorChartData();
            },
            // 初始化报表属性
            initChartDataOption() {

                this.chartDataOption = {
                    title: {
                        text: '辐照仪数据',
                        left:"left",
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: '时间：<b>{b0}</b><br/>辐照量：<b>{c0} J/㎡</b>',
                    },
                    legend: {
                        data: ['辐照量'],
                        selected: {
                            '辐照量': true
                        }
                    },
                    grid: [
                        { left: '5%', right: '5%', top: '10%', height: '85%'}
                    ],
                    xAxis: {
                        type: 'category',
                        data: []
                    },
                    yAxis: {type: 'value'},
                    series: [{
                        name: '辐照量',
                        data: [],
                        type: 'line',
                        smooth: true,
                    }]
                };
                this.chartNoDataOption = {
                    title: [
                        this.chartDataOption.title,
                        {
                            text: '暂无数据',
                            x: 'center',
                            y: 'center',
                            textStyle: {
                                fontSize: 18,
                                fontWeight: 'normal'
                            }
                        }
                    ],
                    yAxis: this.chartDataOption.yAxis,
                    xAxis: this.chartDataOption.xAxis,
                    grid: this.chartDataOption.grid
                }
            },
            // 查询报表数据
            queryData() {

                this.$refs['queryForm'].validate((valid) => {

                    if (valid) {

                        this.queryIrradiatorChartData();
                    } else {

                        return false;
                    }
                });
            },
            // 查询报表数据
            queryIrradiatorChartData() {

                this.loading = true;
                let params = {

                    devicePartsId: this.devicePartsId,
                    startDate: this.formatDate(this.queryForm.dateRange[0]),
                    endDate: this.formatDate(this.queryForm.dateRange[1])
                };
                queryIrradiatorChartData(params).then((res) => {

                    this.loading = false;
                    if (res.code === '200') {

                        let info = res.data;
                        this.chartDataOption.series[0].data = info.solarRadiation;
                        this.chartDataOption.xAxis.data = info.dates;
                        let fzChartDataDiv = echarts.init(document.getElementById('fzChartDataDiv'));
                        fzChartDataDiv.clear();
                        if (info.dates.length > 0) {

                            fzChartDataDiv.setOption(this.chartDataOption);
                        } else {

                            fzChartDataDiv.setOption(this.chartNoDataOption);
                        }
                    } else {

                        message.confirm('获取辐照仪报表数据发生失败,' + res.msg, false, "error");
                    }
                }).catch(err => console.error(err));
            },
            /**
             * 将时间转换成日期格式
             * @param date 时间
             * @returns {string} 日期格式
             */
            formatDate(date) {

                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
            }
        }
    }
</script>

<style scoped>

</style>
