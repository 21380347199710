<template>
  <div v-show="DCPCVisible" class="dcpcForm">
    <el-tabs value="gf">

      <el-tab-pane label="光伏模块模拟量" name="gf">
        <el-table :data="powerConverterModuleData.PvModule" border size="small" stripe style="width: 100%"
                  :header-cell-style="{background:'#ECF0F5', color:'#606266', textAlign: 'center'}">
          <el-table-column align="center">
            <template slot-scope="scope">
              {{"光伏模块" + (scope.row.serialNumber)}}
            </template>
          </el-table-column>
          <el-table-column prop="inputDcV" align="center" label="输入直流电压(V)" />
          <el-table-column prop="outputDcV" align="center"  label="输出直流电压(V)"/>
          <el-table-column prop="outputDcI" align="center" label="输出直流电流(A)"/>
          <el-table-column prop="temp" align="center" label="模块温度(℃)" />
          <el-table-column prop="clp" align="center"  label="限流点(%)" />
        </el-table>
        <el-row style="text-align: right;margin-top: 334px;">
          <label style="font-weight: bolder">{{this.dtuStatus}} 最后上报时间：</label>
          <label>{{this.pvModuleForm.reportTime}}</label>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="整流模块模拟量" name="zl">

        <el-form :model="pvModuleForm" ref="pvModuleForm" class="pvModuleForm" :inline="true" :label-position="labelPosition" label-width="80px">
          <el-row style="padding-bottom: 10px">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:40px;position: relative;padding: 10px">
              <legend style="font-weight: bolder;">直流母排</legend>
              <el-col style="margin-top: -10px;">
                <el-form-item label="电压：">{{this.dcForm.dcBusV}} V</el-form-item>
              </el-col>
            </fieldset>
          </el-row>
        </el-form>

        <el-table :data="powerConverterModuleData.RcModule" border size="small" stripe style="width: 100%"
                  :header-cell-style="{background:'#ECF0F5', color:'#606266', textAlign: 'center'}">
          <el-table-column align="center">
            <template slot-scope="scope">
              {{"整流模块" + (scope.row.serialNumber)}}
            </template>
          </el-table-column>
          <el-table-column prop="inputAcV" align="center" label="输入交流电压(V)" />
          <el-table-column prop="outputDcV" align="center"  label="输出直流电压(V)"/>
          <el-table-column prop="outputDcI" align="center" label="输出直流电流(A)"/>
          <el-table-column prop="temp" align="center" label="模块温度(℃)" />
          <el-table-column prop="clp" align="center"  label="限流点(%)" />
        </el-table>
        <el-row style="text-align: right;margin-top: 303px;">
          <label style="font-weight: bolder">{{this.dtuStatus}} 最后上报时间：</label>
          <label>{{this.pvModuleForm.reportTime}}</label>
        </el-row>
      </el-tab-pane>


      <el-tab-pane label="直流模拟量" name="dc">
        <el-form :model="dcForm" ref="pvModuleForm" class="pvModuleForm" :inline="true">
          <el-row style="margin-bottom: 15px;">
            <el-col :span="24" style="height: 590px;border: 0px red solid;">
              <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                <legend style="font-weight: bolder;">直流屏1</legend>
                <el-row style="margin-left: 28px;margin-top: 25px">
                  <el-col :span="6">
                    <el-form-item label="直流输出电压："></el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>{{this.dcForm.dcBusV}} V</el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-left: 28px;margin-top: 15px">
                  <el-col :span="6">
                    <el-form-item label="总负载电流："></el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>{{this.dcForm.dcReloadC}} A</el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-left: 28px;margin-top: 15px">
                  <el-col :span="6">
                    <el-form-item label="锂电池组充放电电流："></el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>{{this.dcForm.batChargeDischargeC}} A</el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-left: 28px;margin-top: 15px">
                  <el-col :span="6">
                    <el-form-item label="设备舱温度："></el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>{{this.dcForm.mcTemp1}} 度</el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-left: 28px;margin-top: 15px">
                  <el-col :span="6">
                    <el-form-item label="电控舱温度："></el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>{{this.dcForm.mcTemp2}} 度</el-form-item>
                  </el-col>
                </el-row>
              </fieldset>
            </el-col>
          </el-row>
          <el-row style="text-align: right;margin-top: 25px;">
            <label style="font-weight: bolder">{{this.dtuStatus}} 最后上报时间：</label>
            <label>{{this.dcForm.reportTime}}</label>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {queryDCPCReportData, queryPowerConverterModuleData} from "../../../../api/station/deviceInfoApi";
export default {
  name: "dcPowerConverterMain",
  props: {
    DCPCVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {

    return {

      labelPosition: 'left',
      powerConverterModuleData:{},
      pvModuleForm: {

        reportTime: '无'
      },
      dcForm: {

        mcTemp1: '0',
        mcTemp2: '0',
        dcBusV: '0',
        dcReloadC: '0',
        batChargeDischargeC: '0',
        reportTime: '无'
      },
      dtuStatus: '',
    }
  },
  methods: {

    // 日期格式化
    dateFormat(cellValue) {

      let date = new Date(cellValue);
      // 日期格式不足两位补零
      function appendZero(str) {

        if(str < 10) {

          str = "0" +""+ str
        }
        return str;
      }
      return date.getFullYear()  + "-" + appendZero(date.getMonth() + 1) + "-" + appendZero(date.getDate()) + " "
          + appendZero(date.getHours()) + ":" + appendZero(date.getMinutes())  + ":" + appendZero(date.getSeconds());
    },

    // 初始化直流电源变换器页面
    initDCPCPage(deviceNum) {

      // 重置数据为默认值
      Object.assign(this.$data, this.$options.data());
      let params = {

        deviceNum: deviceNum
      };

      queryPowerConverterModuleData(params).then((res) => {

        this.powerConverterModuleData = res;
        if (res.PvModule) {
          console.log(res.PvModule[0].reportTime)
          this.pvModuleForm.reportTime = this.dateFormat(res.PvModule[0].reportTime)
        }

      });

      queryDCPCReportData(params).then((res) => {

        if (res.data.dcForm != null) {

          this.dtuStatus = res.data.dtuStatus
          this.dcForm = res.data.dcForm;
        }
      })
    }
  }
}
</script>
<style>
.dcpcForm .el-form-item__label{

  font-weight: bolder;
}
.dcpcForm .el-form-item{

  height: 5px;
}
</style>
