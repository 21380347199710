<template>
    <div v-show="batteryAllVisible">
        <el-form class="engineForm" :inline="true">
            <el-row style="margin-bottom: 12px;">
                <el-col :span="24" style="height: 650px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">电池组总览</legend>
                        <el-row  style="margin-top: 15px;margin-bottom: 0px">
                          <el-button type="primary" size="small" @click="openBatteryChartPage()">统计</el-button>
                        </el-row>
                        <el-row  style="margin-top: 15px;margin-bottom: 0px">
                            <el-col :span="4" style="visibility:hidden">
                                <el-form-item style="font-weight:bold">电池包序号</el-form-item>
                            </el-col>
                            <el-col :span="2" v-for="(num, index) in batteryPackNums">
                                <el-form-item style="font-weight:bold;font-size: 12px">电池包{{index + 1}}</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row  style="margin-top: 0px;margin-bottom: 30px">
                            <el-col :span="4" style="visibility:hidden">
                                <el-form-item style="font-weight:bold">电池包名称</el-form-item>
                            </el-col>
                            <el-col :span="2" v-for="(num, index) in batteryPackNums">
                                <el-form-item style="font-weight:bold;font-size: 12px;margin-left: 6px">({{num}})</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-for="(value,index) in batteryPackageDatas" :key="index" style="margin-top: 15px">
                            <el-col :span="4" v-if="index === 0">
                                <el-form-item style="font-weight:bold">直流电压(V)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 1">
                                <el-form-item style="font-weight:bold">直流电流(A)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 2">
                                <el-form-item style="font-weight:bold">循环次数</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 3">
                                <el-form-item style="font-weight:bold">SOC(%)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 4">
                                <el-form-item style="font-weight:bold">SOH(%)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 5">
                                <el-form-item style="font-weight:bold">最高温度(℃)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 6">
                                <el-form-item style="font-weight:bold">最低温度(℃)</el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="index === 7">
                                <el-form-item style="font-weight:bold">平均温度(℃)</el-form-item>
                            </el-col>
                            <el-col :span="2"  v-for="item in value">
                                <el-form-item style="margin-left: 5px">{{item}}</el-form-item>
                            </el-col>
                        </el-row>

                    </fieldset>
                </el-col>
            </el-row>
            <el-row style="text-align: right;margin-top: 23px;">
                <label style="font-weight: bolder">{{this.dtuWorkStatus}} 最后上报时间：</label>
                <label>{{this.reportTime}}</label>
            </el-row>
        </el-form>
      <el-dialog title="电池信息" :visible.sync="batteryChartsVisible"  :destroy-on-close="true" :append-to-body="true"
                 width="80%" top="8vh">
        <div style="margin-bottom: 20px">
          <div style="display: flex; margin-bottom: 20px;margin-left: 10px;">
<!--            <el-checkbox :indeterminate="isIndeterminateNumbers" v-model="checkAllNumbers" @change="handleCheckAllNumbersChange" style="margin-right: 25px">全选</el-checkbox>-->
            <el-checkbox-group v-model="checkedNumbers" @change="handleCheckedNumbersChange">
              <el-checkbox v-for="(item, index) in numberOptions" :label="item" :key="index">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div style="display: flex; align-items: center;margin-left: 10px;">
<!--            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 25px">全选</el-checkbox>-->
            <el-checkbox-group v-model="checkedValues" @change="handleCheckedValuesChange">
              <el-checkbox v-for="(item, index) in valuesOptions" :label="item" :key="index">{{item.name}}</el-checkbox>
            </el-checkbox-group>
            <div class="block" style="margin-left: 20px;">
              <label>日期：</label>
              <el-date-picker v-model="batteryInfoDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
              <el-button style="margin-left: 10px;" type="primary" @click="queryBatteryTemps()">查询</el-button>
            </div>
          </div>
        </div>
        <div id="batteryTemp" style="width:100%;height:500px;"></div>
      </el-dialog>
    </div>
</template>
<script>

import {queryBatteryPackageAllReportData, queryBatteryTemps} from "../../../../api/station/deviceInfoApi";
import echarts from "echarts";
    export default {
        name: "batteryPackageAllMain",
        props: {
            batteryAllVisible:{
                type: Boolean,
                default: false
            },
            deviceId:''
        },
        data() {

            return {

                batteryPackageDatas:[],
                batteryPackNums:[],
                reportTime: null,
                dtuWorkStatus:"",
                batteryPackageForms:{},
                batteryPackageNum: '',
                batteryChartsVisible: false,
                batteryInfoDate: new Date(),
                valuesOptions : [
                    {'name': '直流电压','id':'bat_dcv','unit': 'V'},
                    {'name': '直流电流','id':'bat_dci','unit': 'A'},
                    {'name': '循环次数','id':'bat_loop_count','unit': ''},
                    {'name': 'SOC','id':'bat_soc','unit': '%'},
                    {'name': 'SOH','id':'bat_soh','unit': '%'},
                    {'name': '最高温度','id':'bat_temp_max','unit': '℃'},
                    {'name': '最低温度','id':'bat_temp_min','unit': '℃'},
                    {'name': '平均温度','id':'bat_temp_avg','unit': '℃'}
                ],
                checkAll: false,
                checkedValues: [],
                valuesLength: 8,
                isIndeterminate: false,
                numberOptions:[
                    {'name':'电池包1','id': '1'},
                    {'name':'电池包2','id': '2'},
                    {'name':'电池包3','id': '3'},
                    {'name':'电池包4','id': '4'},
                    {'name':'电池包5','id': '5'},
                    {'name':'电池包6','id': '6'},
                    {'name':'电池包7','id': '7'},
                    {'name':'电池包8','id': '8'},
                    {'name':'电池包9','id': '9'},
                    {'name':'电池包10','id': '10'}
                ],
                checkedNumbers:[],
                isIndeterminateNumbers: false,
                checkAllNumbers: false,
                numbersLength:10
            }
        },
        methods: {

            // 初始化电池包详情页面
            initBatteryAllPage(deviceNum, children) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());

                for (let i = 0; i < children.length; i++) {

                    this.batteryPackNums[i] = children[i].num;
                }
                let params = {

                    deviceNum: deviceNum
                };
                queryBatteryPackageAllReportData(params).then((res) => {


                    if (res.data != null) {

                        this.batteryPackageForms = res.data
                        let batDciArr = [];
                        let batDcvARR = [];
                        let batLoopCountArr = [];
                        let batSocArr = [];
                        let batSohArr = [];
                        let batTempMaxArr = [];
                        let batTempMinArr = [];
                        let batTempAvgArr = [];
                        for (let i = 0; i < res.data.length; i++) {

                            batDcvARR[i] = res.data[i].batDcv;
                            batDciArr[i] = res.data[i].batDci;
                            batLoopCountArr[i] = res.data[i].batLoopCount;
                            batSocArr[i] = res.data[i].batSoc;
                            batSohArr[i] = res.data[i].batSoh;
                            batTempMaxArr[i] = res.data[i].batTempMax;
                            batTempMinArr[i] = res.data[i].batTempMin;
                            batTempAvgArr[i] = res.data[i].batTempAvg;
                        }
                        this.batteryPackageDatas.push(batDcvARR);
                        this.batteryPackageDatas.push(batDciArr);
                        this.batteryPackageDatas.push(batLoopCountArr);
                        this.batteryPackageDatas.push(batSocArr);
                        this.batteryPackageDatas.push(batSohArr);
                        this.batteryPackageDatas.push(batTempMaxArr);
                        this.batteryPackageDatas.push(batTempMinArr);
                        this.batteryPackageDatas.push(batTempAvgArr);
                        this.reportTime = this.batteryPackageForms[0].reportTime;
                        this.dtuWorkStatus = this.batteryPackageForms[0].dtuWorkStatus;
                    }
                }).catch(err => console.error(err));
            },
            openBatteryChartPage() {

                this.batteryChartsVisible = true;
            },
            queryBatteryTemps() {

                if (this.checkedNumbers.length === 0 || this.checkedValues === 0) {

                    this.$message({

                        message: '电池包与电池属性至少选一个',
                        center: true,
                        type:'warning'
                    });
                    return;
                }
                if (this.batteryInfoDate == null || this.batteryInfoDate === '') {

                    this.$message({

                      message: '日期必须选择',
                      center: true,
                      type:'warning'
                    });
                    return;
                }
                let that = this;
                let param = {

                      "deviceId": this.deviceId,
                      "date": this.batteryInfoDate,
                      "checkedValues": this.checkedValues,
                      "checkedNumbers": this.checkedNumbers
                  };
                  let myChart = echarts.init(document.getElementById('batteryTemp'));
                  myChart.showLoading({

                      text: '数据加载中...',
                      color: '#ccc',
                      textColor: '#000',
                      maskColor: 'rgba(255, 255, 255, 0.3)',
                      zlevel: 0,
                  });
                  queryBatteryTemps(param).then((res) => {

                    myChart.hideLoading();
                    let time = res.timeList;
                    let chartData = [];
                    for (let key in res.dataMap) {

                        let dataArr = [];
                        for (let index in res.dataMap[key]) {

                          dataArr.push(res.dataMap[key][index]);
                        }
                        chartData.push({

                          type: 'line',
                          name: key,
                          data: dataArr,
                          smooth: true,
                          // 去掉圆点
                          showSymbol: false
                        });
                    }
                    // 按照名字排序 zh-CN
                    chartData.sort(function (a, b) { return a.name.localeCompare(b.name, 'en-u-kn-true'); })
                    echarts.init(document.getElementById('batteryTemp')).clear();
                    let batteryTempOp = {

                        // 没有数据时显示暂无数据
                        graphic: {
                          type: 'text',
                          left: 'center',
                          top: 'middle',
                          invisible: chartData.length > 0,
                          style: {
                            fontWeight: 'bold',
                            text: '暂无数据',
                            fontSize: '20'
                          }
                        },
                        tooltip: {

                          trigger: 'axis',
                          formatter: function(params) {

                                let arr = that.subGroup(params, 20);
                                let htmlStr = '';
                                for (let i = 0; i < arr.length; i++) {

                                  htmlStr += '<div>';
                                  for (let j = 0; j < arr[i].length; j++) {

                                    let data = arr[i][j];
                                    let unit = "";
                                    let name = data.seriesName;
                                    let seriesArr = data.seriesName.split("-");
                                    if (seriesArr.length > 1) {

                                      name = seriesArr[0];
                                      unit = seriesArr[1];
                                    }
                                    htmlStr += `<div style="height:18px;width: 180px;">
                                                    <i style="width: 10px;height: 10px;display: inline-block;background: ${data.color};border-radius: 10px;"></i>
                                                    <span>${name}: ${data.data}${unit}</span>
                                                 </div>`
                                  }
                                  htmlStr += '</div>'
                                }
                                return '<div style="font-size: 12px; display: flex">' + htmlStr + '</div>';
                            }
                        },
                        legend: {

                          type: 'scroll',
                          orient: 'vertical',
                          left: 'right',
                          top: 0,
                          textStyle: {
                            "fontSize": 10
                          }
                        },
                        grid: {
                          top: '3%',
                          left: '1%',
                          right: '10%',
                          bottom: '3%',
                          containLabel: true,
                          width: "88%"
                        },
                        xAxis: {
                          type: 'category',
                          data: time
                        },
                        yAxis: {
                          type: 'value'
                        },
                        series : chartData
                      };
                      let renderData = echarts.init(document.getElementById('batteryTemp'));
                      renderData.setOption(batteryTempOp);
                  });
            },
            subGroup(arr, len) {

                let newArr = [];
                for (let i = 0; i < arr.length; i += len) {

                    newArr.push(arr.slice(i, i + len));
                }
                return newArr;
            },
            handleCheckAllChange(val) {

                this.checkedValues = val ? this.valuesOptions : [];
                this.isIndeterminate = false;
            },
            handleCheckedValuesChange(value) {

                let checkedCount = value.length;
                this.checkAll = checkedCount === this.valuesLength;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.valuesLength;
            },
            handleCheckAllNumbersChange(val) {

              this.checkedNumbers = val ? this.numberOptions : [];
              this.isIndeterminateNumbers = false;
            },
            handleCheckedNumbersChange(value) {

              let checkedCount = value.length;
              this.checkAllNumbers = checkedCount === this.numbersLength;
              this.isIndeterminateNumbers = checkedCount > 0 && checkedCount < this.numbersLength;
            }
        }
    }
</script>
<style>
</style>
<style scoped>
</style>
