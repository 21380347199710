<template>
    <div v-show="DIDOVisible">
        <el-row style="margin-bottom: 12px;">
            <el-col :span="12" style="height: 650px;border: 0px red solid;">
                <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                    <legend style="font-weight: bolder;">DI</legend>
                    <el-form :model="diForm" ref="diForm" class="engineForm" :inline="true">
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="动力舱开门反馈："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.powerCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="电控舱开门反馈："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.electricCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="设备舱开门反馈："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.deviceCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="动力舱和设备舱水浸："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.electricDeviceCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="电控舱烟感："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.electricCabinSmoke}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="设备舱烟感："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.deviceCabinSmoke}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="柴发急停开关反馈："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.emerStopSwitch}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="柴发水套加热反馈："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.diForm.waterHeating}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </fieldset>
            </el-col>
            <el-col :span="12" style="height: 650px;border: 0px red solid;">
                <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                    <legend style="font-weight: bolder;">DO</legend>
                    <el-form :model="doForm" ref="diForm" class="engineForm" :inline="true">
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="动力舱开门："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.powerCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="电力舱开门："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.electricCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="设备舱开门："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.deviceCabin}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="工控机侧开门："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.ipc}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="电控舱风机启动："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.electricFan}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="柴发机组启动："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.dgenSet}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="柴发排烟管阀门电磁阀启动："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.solenoidValue}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="18">
                                <el-form-item label="柴发水套加热启动："></el-form-item>
                            </el-col>
                            <el-col :span="6" style="margin-top: 13px">
                                <el-form-item>
                                    <div class="light" :class="{greenLight:this.doForm.waterHeatingStart}"></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </fieldset>
            </el-col>
        </el-row>
        <el-row style="text-align: right;margin-top: 25px;">
            <label style="font-weight: bolder">{{this.dtuWorkStatus}} 最后上报时间：</label>
            <label>{{this.reportTime}}</label>
        </el-row>
    </div>
</template>
<script>
    import {queryDIDOInfoData} from "../../../../api/station/deviceInfoApi";

    export default {
        name: "DIDOInfoMain",
        props: {
            DIDOVisible:{
                type: Boolean,
                default: false
            },
        },
        data() {

            return {

                dtuWorkStatus: "",
                reportTime: "",
                diForm : {

                    powerCabin: true,
                    electricCabin: true,
                    deviceCabin: true,
                    electricDeviceCabin: true,
                    electricCabinSmoke: true,
                    deviceCabinSmoke: true,
                    emerStopSwitch: true,
                    waterHeating: true
                },
                doForm: {

                    powerCabin: true,
                    electricCabin: true,
                    deviceCabin: true,
                    ipc: true,
                    electricFan: true,
                    dgenSet: true,
                    solenoidValue: true,
                    waterHeatingStart: true
                }
            }
        },
        methods : {

            initDIDOPage(deviceNum) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                let params = {

                    deviceNum: deviceNum
                };
                queryDIDOInfoData(params).then((res) => {

                    if (res.data.diForm != null) {

                        this.diForm = res.data.diForm
                    }
                    if (res.data.doForm != null) {

                        this.doForm = res.data.doForm;
                    }
                    this.dtuWorkStatus = res.data.dtuWorkStatus;
                    this.reportTime = res.data.reportTime;
                }).catch(err => console.error(err));
            }
        }
    }
</script>
