<template>
  <div v-show="overViewVisible">
    <el-row>
      <el-col :span="21" style="font-size: 28px;padding:4px;margin-top: -5px">
        光储柴能源管理系统
      </el-col>
      <el-col :span="3" style="font-size: 28px;padding:4px;margin-top: -5px">
        <el-button style="float: right" size="small" type="danger" title="此操作会影响数据上报，请谨慎操作" @click="openBdRecordPage(currWholeId)">底度管理</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 18px;">
      <el-col :span="16" >
        <el-row style="margin-bottom: 18px;">
          <el-col :span="12" style="height: 300px;border: 0px red solid;">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%">
              <legend style="font-weight: bolder;">直流电源变换器</legend>
              <el-form :model="transferForm" ref="transferForm" :inline="true" >
                <fieldset class="myField1">
                  <div style="height: 220px">
                    <el-scrollbar style="height: 100%;">
                      <el-row v-for="num in showLength">
                        <el-col :span="9">
                          <el-form-item v-if="(num - 1) < pvLength" style="height: 22px;padding-left: 20px;" :label="pvStatusForm[num-1].majorClassName + pvStatusForm[num-1].serialNumber">
                          </el-form-item>
                          <el-form-item v-else style="height: 22px;padding-left: 20px;" label=" ">
                          </el-form-item>
                        </el-col>
                        <el-col :span="3">
                          <el-form-item v-if="(num - 1) < pvLength" style="height: 22px;">
                            <div v-html="getStatusIcon(pvStatusForm[num-1].status)"></div>
                          </el-form-item>
                          <el-form-item v-else style="height: 22px;padding-left: 20px;" label=" ">
                          </el-form-item>
                        </el-col>

                        <el-col :span="9">
                          <el-form-item v-if="(num - 1) < rcLength" style="height: 22px;padding-left: 20px;" :label="rcStatusForm[num-1].majorClassName + rcStatusForm[num-1].serialNumber">
                          </el-form-item>
                          <el-form-item v-else style="height: 22px;padding-left: 20px;" label=" ">
                          </el-form-item>
                        </el-col>
                        <el-col :span="3">
                          <el-form-item v-if="(num - 1) < rcLength" style="height: 22px;">
                            <div v-html="getStatusIcon(rcStatusForm[num-1].status)"></div>
                          </el-form-item>
                          <el-form-item v-else style="height: 22px;padding-left: 20px;" label=" ">
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-scrollbar>
                  </div>
                </fieldset>
                <el-row style="margin-left: 28px">
                  <el-form-item style="height: 5px" label="柴发累计发电量：">
                    {{this.genInputTotQ}}kWh
                  </el-form-item>
                </el-row>
                <el-row style="margin-left: 28px">
                  <el-form-item style="height: 5px" label="光伏累计发电量：">
                    {{this.pvOutputTotQ}}kWh
                  </el-form-item>
                </el-row>
              </el-form>
            </fieldset>
          </el-col>
          <el-col :span="12" style="height: 300px;border: 0px red solid;">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%">
              <legend style="font-weight: bolder;">柴油发电机</legend>
              <el-form :model="cfForm" ref="cfForm" :inline="true" >
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px;padding-left: 20px;" label="低油压">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;">
                      <div v-html="getIcon(this.cfForm.lowOilPress)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="高水温">
                    </el-form-item>
                  </el-col>
                  <el-row :span="3">
                    <el-form-item style="height: 22px;">
                      <div v-html="getIcon(this.cfForm.highWaterTemp)"></div>
                    </el-form-item>
                  </el-row>
                </el-row>
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px; padding-left: 20px;" label="低水位">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;" >
                      <div v-html="getIcon(this.cfForm.lowWaterLevel)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="超速">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;" >
                      <div v-html="getIcon(this.cfForm.speeding)"></div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px;padding-left: 20px;" label="急停">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;" >
                      <div v-html="getIcon(this.cfForm.emergencyStop)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="启动失败">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px" >
                      <div v-html="getIcon(this.cfForm.startFail)"></div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px;padding-left: 20px;" label="电池电压低">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;" >
                      <div v-html="getIcon(this.cfForm.lowBatteryVol)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="维护时间到" >
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px">
                      <div v-html="getIcon(this.cfForm.maintenceTime)"></div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px;padding-left: 20px;" label="高电压">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;">
                      <div v-html="getIcon(this.cfForm.highVol)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="低电压">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px">
                      <div v-html="getIcon(this.cfForm.lowVol)"></div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="9">
                    <el-form-item style="height: 22px;padding-left: 20px;" label="高频率">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px;" >
                      <div v-html="getIcon(this.cfForm.highVol)"></div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item style="height: 22px" label="低频率">
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="height: 22px">
                      <div v-html="getIcon(this.cfForm.lowFreq)"></div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </fieldset>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="12" style="height: 300px;border: 0px red solid;">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
              <legend style="font-weight: bolder;">操作历史记录</legend>
              <el-row v-for="record in optionRecords" style="margin-top: 30px;text-align: center;">
                <label>{{record.opTime}}  {{record.opName}}</label>
              </el-row>
              <el-button class="detailButton" @click="openOpRecordPage(currWholeId, currDeviceNum)">更多</el-button>
            </fieldset>
          </el-col>
          <el-col :span="12" style="height: 300px;border: 0px red solid;">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
              <legend style="font-weight: bolder;">告警记录</legend>
              <el-row v-for="record in alarmRecords" style="margin-top: 20px;text-align: left;">
                <el-col :span="22">
                  {{record.reportTime}} {{record.minorFaultName}}
                </el-col>
                <el-col :span="2">
                  <span v-html="getSeriousIcon(record.seriousLevel)"></span>
                </el-col>
              </el-row>
              <el-button class="detailButton" @click="openWarningInfoPage(currWholeId)">更多</el-button>
            </fieldset>
          </el-col>
          <el-col :span="12" style="height: 300px;border: 0px red solid;display: none">
            <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
              <legend style="font-weight: bolder;">事件记录</legend>
              <el-row v-for="record in eventsRecords" style="margin-top: 18px;text-align: left;">
                <el-col :span="22">
                  {{record.reportTime}} {{record.name}}
                </el-col>
                <el-col :span="2">
                  <span v-html="getLevelIcon(record.level)"></span>
                </el-col>
              </el-row>
              <el-button class="detailButton" @click="openParentEventsRecordPage(currWholeId)">更多</el-button>
            </fieldset>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" style="height: 618px;border: 0px red solid;">
        <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%">
          <legend style="font-weight: bolder;">锂电池组</legend>
          <el-form :model="batteryForm" ref="batteryForm" :inline="true">
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="1 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv1}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci1}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="2 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv2}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci2}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="3 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv3}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci3}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="4 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv4}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci4}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="5 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv5}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci5}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="6 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv6}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci6}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="7 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv7}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci7}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="8 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv8}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci8}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="9 " style="height: 20px"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv9}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci9}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3" style="text-align: right">
                <el-form-item label="10 " style="height: 20px;margin-left: auto"></el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电压：" style="height: 20px">{{this.batteryForm.batDcv10}}V</el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="电流：" style="height: 20px">{{this.batteryForm.batDci10}}A</el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-top: 30px;">
              <el-col :span="12" style="margin-left: 25px">
                <el-form-item label="吸收电量：" style="height: 20px;width: 200px">
                  {{this.batAbsorbTotQ}} kWh
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" style="margin-left: 25px">
                <el-form-item label="释放电量：" style="height: 20px;width: 200px">
                  {{this.batFreedTotQ}} kWh
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </fieldset>
      </el-col>
    </el-row>
    <el-row style="text-align: right">
      <label style="font-weight: bolder">{{this.dtuWorkStatus}} 最后上报时间: </label>
      <label>{{this.lastReportTime}}</label>
    </el-row>
    <DeviceBaseDegreeRecord v-bind:bdRecordVisible="bdRecordVisible" ref="bdRecordPage"></DeviceBaseDegreeRecord>
  </div>
</template>
<script>
import {queryDeviceOverviewInfo, queryPowerConverterModuleData} from "../../../../api/station/deviceInfoApi";
import DeviceBaseDegreeRecord from "./device-base-degree-record.vue";
let echarts = require('echarts');
export default {
  name: "deviceOverViewMain",
  components: {DeviceBaseDegreeRecord},
  props: {
    overViewVisible:{
      type: Boolean,
      default: false
    },
  },
  data() {

    return  {

      powerConverterModuleData:{},
      transferForm: {

      },
      cfForm: {

        lowOilPress: '0',
        highWaterTemp: '0',
        lowWaterLevel: '0',
        speeding: '0',
        emergencyStop: '0',
        startFail: '0',
        lowBatteryVol: '0',
        maintenceTime: '0',
        highVol: '0',
        lowVol: '0',
        highFreq: '0',
        lowFreq: '0'
      },
      optionRecords: [],
      alarmRecords: [],
      eventsRecords:[],
      batteryForm: {

        batDci1: '0',
        batDci2: '0',
        batDci3: '0',
        batDci4: '0',
        batDci5: '0',
        batDci6: '0',
        batDci7: '0',
        batDci8: '0',
        batDci9: '0',
        batDci10: '0',
        batDcv1:'0',
        batDcv2:'0',
        batDcv3:'0',
        batDcv4:'0',
        batDcv5:'0',
        batDcv6:'0',
        batDcv7:'0',
        batDcv8:'0',
        batDcv9:'0',
        batDcv10:'0'
      },
      batAbsorbTotQ: '0',
      batFreedTotQ: '0',
      genInputTotQ: '0',
      pvOutputTotQ: '0',
      lastReportTime: '无',
      dtuWorkStatus: "",
      currWholeId: '',
      currDeviceNum: '',
      bdRecordVisible: false,
      batteryInfoVisible: false,
      batteryInfoDate: new Date(),
      batteryId: "",
      // 循环展示长度
      showLength: 0,
      pvLength: 0,
      rcLength: 0,
      // 变换器状态对象
      pvStatusForm: [],
      rcStatusForm: [],

    }
  },
  inject:['openOptionRecordPage', 'openWarnInfoPage', 'openEventsRecordPage'],
  methods: {

    // 打开底度设置页面
    openBdRecordPage(id) {

      this.bdRecordVisible = true;
      this.$refs.bdRecordPage.initRecordPage(id);
    },
    closeBdRecordPage() {

      this.bdRecordVisible = false;
    },
    // 打开操作记录页面
    openOpRecordPage(id, currDeviceNum) {

      this.openOptionRecordPage(id, currDeviceNum);
    },
    // 打开告警信息页面
    openWarningInfoPage(id) {

      this.openWarnInfoPage(id);
    },
    openParentEventsRecordPage(id) {

      this.openEventsRecordPage(id);
    },
    // 查询相关的总览信息并渲染
    queryOverViewInfo(params) {

      queryPowerConverterModuleData(params).then((res) => {

        this.powerConverterModuleData = res;
        this.pvStatusForm = res.PvModuleStatus
        this.rcStatusForm = res.RcModuleStatus
        this.pvLength = res.PvModuleStatus.length
        this.rcLength = res.RcModuleStatus.length
        this.showLength = this.pvLength > this.rcLength ? this.pvLength : this.rcLength
      });

      // 重置数据为默认值
      Object.assign(this.$data, this.$options.data());
      // 查询
      this.currWholeId = params.id;
      this.currDeviceNum = params.deviceNum;
      queryDeviceOverviewInfo(params).then((res) => {

        let data = res.data;
        if (data.engineStatusForm != null) {

          this.cfForm = data.engineStatusForm;
        }
        if (data.batteryForm != null) {
          this.batteryForm = data.batteryForm;
        }
        if (data.genInputTotQ != null) {

          this.genInputTotQ = data.genInputTotQ;
        }
        if (data.pvOutputTotQ != null) {

          this.pvOutputTotQ = data.pvOutputTotQ;
        }
        if (data.batAbsorbTotQ != null) {

          this.batAbsorbTotQ  = data.batAbsorbTotQ;
        }
        if (data.batFreedTotQ != null) {

          this.batFreedTotQ = data.batFreedTotQ;
        }
        this.optionRecords = data.opRecords;
        this.alarmRecords = data.alarmRecords;
        this.eventsRecords = data.eventsRecords;
        this.lastReportTime = data.lastReportTime;
        this.dtuWorkStatus = data.dtuWorkStatus;
      });
    },
    // 获取电源变换器状态
    getStatusIcon(param) {

      if (param && param == "2") {

        return "<i class='el-icon-error' style='color:#F56C6C;'></i>";
      } else {

        return "<i class='el-icon-success' style='color:#67C23A;'></i>";
      }
    },
    // 获取图标
    getIcon(param) {

      if (param != null && param == "1") {

        return "<i class='el-icon-error' style='color:#F56C6C;'></i>";
      } else {

        return "<i class='el-icon-success' style='color:#67C23A;'></i>";
      }
    },
    // 获取严重程度图标
    getSeriousIcon(level) {

      if (level == 'middle') {

        return "<div class='myCircle' style='background-color: #e6a23c'></div>";
      } else if (level == 'senior') {

        return "<div class='myCircle' style='background-color: #f56c6c'></div>";
      }
    },
    getLevelIcon(level) {

      if (level == '3') {

        return "<div class='myCircle' style='background-color: #f56c6c'></div>";
      } else {

        return "<div class='myCircle' style='background-color: #e6a23c'></div>";
      }
    }
  }
}
</script>
<style scoped>
.myField1 {
  border:1px solid #ccc;
  background-color: #ECF0F5;
  height:100%;
  padding: 0px 0px 0px 5px;
}
.el-form-item__label {
  padding: initial;
}
</style>
<style>
.myCircle {

  width: 10px;
  height: 10px;
  border-radius:50%;
  line-height: 2.5;
  color: white;
  text-align: center;
  margin-top: 6px;
}
</style>
