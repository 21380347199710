<template>
    <div>
        <el-container>
            <el-aside class="dialog-aside">
                <el-tree :data="treeData" @node-click="handleNodeClick" :highlight-current="true"
                         :expand-on-click-node="false" default-expand-all :render-content="renderContent"></el-tree>
            </el-aside>
            <el-main class="dialog-main" style="padding: 20px 20px 0px 20px;">
                <DeviceOverViewMain :overViewVisible="overViewVisible" ref="overViewPage"></DeviceOverViewMain>
                <DeviceEngineMain :engineVisible="engineVisible" ref="engineViewPage"></DeviceEngineMain>
                <BatteryPackageMain :batteryVisible="batteryVisible" ref="batteryPackagePage"></BatteryPackageMain>
                <BatteryPackageAllMain :batteryAllVisible="batteryAllVisible" ref="batteryPackageAllPage" :deviceId = "currWholeId"></BatteryPackageAllMain>
                <DcPowerConverterMain :DCPCVisible="DCPCVisible" ref="DCPCPage"></DcPowerConverterMain>
                <CommunicationMain :CommVisible="CommVisible" ref="communicationMainPage"></CommunicationMain>
                <DIDOInfoMain :DIDOVisible="DIDOVisible" ref="DIDOInfoPage"></DIDOInfoMain>
                <MeteorographMain :meteorographMainVisible="meteorographMainVisible" ref="meteorographMainPage"></MeteorographMain>
                <IrradiatorMain :irradiatorMainVisible="irradiatorMainVisible" ref="irradiatorMainPage"></IrradiatorMain>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import {queryDevicePartsInfoById} from "@/api/station/deviceInfoApi";
import DeviceOverViewMain from "@/views/station/device/device-info/device-over-view-main.vue";
import DeviceEngineMain from "@/views/station/device/device-info/device-engine-main.vue";
import BatteryPackageMain from "@/views/station/device/device-info/battery-package-main.vue";
import BatteryPackageAllMain from "@/views/station/device/device-info/battery-package-all-main.vue";
import DcPowerConverterMain from "@/views/station/device/device-info/dc-power-converter-main.vue";
import CommunicationMain from "@/views/station/device/device-info/communication-main.vue";
import DIDOInfoMain from "@/views/station/device/device-info/di-do-info-main.vue";
import gkIcon from "@/assets/icon/gk.png";
import IrradiatorMain from "@/views/station/device/device-info/irradiator-main.vue";
import MeteorographMain from "@/views/station/device/device-info/meteorograph-main.vue";

export default {
    name: "device-info-index",
    components: {
        MeteorographMain,
        IrradiatorMain,
        DeviceOverViewMain,
        DeviceEngineMain,
        BatteryPackageMain,
        BatteryPackageAllMain,
        DcPowerConverterMain,
        CommunicationMain,
        DIDOInfoMain
    },
    data() {
        return {

            currWholeId: '',
            currDeviceNum: '',
            overViewVisible: false,
            engineVisible: false,
            batteryVisible: false,
            batteryAllVisible: false,
            DCPCVisible: false,
            DIDOVisible: false,
            CommVisible: false,
            meteorographMainVisible: false,
            irradiatorMainVisible: false,
            treeDataId: 1,
            treeData: []
        }
    },
    methods: {

        // 初始化页面
        initPage(id, deviceNum) {

            let root = require('../../../assets/icon/root.png');
            let cfIcon = require('../../../assets/icon/cf.png');
            let dcIcon = require('../../../assets/icon/dc.png');
            let gfIcon = require('../../../assets/icon/gf.png');
            let zlIcon = require('../../../assets/icon/zl.png');
            let dyIcon = require('../../../assets/icon/dy.png');
            let ckIcon = require('../../../assets/icon/ck.png');
            let gkIcon = require('../../../assets/icon/gk.png');
            let dtuIcon = require('../../../assets/icon/dtu.png');
            let enIcon = require('../../../assets/icon/en.png');
            let qxIcon = require('../../../assets/icon/qx.png');
            let fzIcon = require('../../../assets/icon/fz.png');
            this.currWholeId = id;
            this.currDeviceNum = deviceNum;
            let params = {

                id : id,
                deviceNum: deviceNum
            };
            queryDevicePartsInfoById(params).then((res) => {

                let tmp = {

                    BatteryPack:[],
                    PvModule:[],
                    RcModule:[],
                    Engine:{},
                    ElectricBall:{},
                    Controller:{},
                    Ipc:{},
                    Mc2600:{},
                    SpServer:{},
                    Dtu:{},
                    qx:{},
                    fz:{}
                };
                let tmpList = res.data;
                for (let i = 0; i < tmpList.length; i++) {

                    let partInfo = tmpList[i];
                    let majorClass = partInfo.majorClass;
                    if (majorClass === 'BatteryPack') {

                        tmp.BatteryPack.push(partInfo);
                    } else if (majorClass === 'PvModule') {

                        tmp.PvModule.push(partInfo);
                    } else if (majorClass === 'RcModule') {

                        tmp.RcModule.push(partInfo);
                    } else if (majorClass === 'SpServer') {

                        tmp.SpServer = partInfo;
                    } else if (majorClass === 'Controller') {

                        tmp.Controller = partInfo;
                    } else if (majorClass === 'Dtu') {
                        tmp.Dtu = partInfo;
                    } else if (majorClass === 'ElectricBall') {

                        tmp.ElectricBall = partInfo;
                    } else if (majorClass === 'Engine') {

                        tmp.Engine = partInfo;
                    } else if (majorClass === 'Ipc') {

                        tmp.Ipc = partInfo;
                    } else if (majorClass === 'Mc2600') {

                        tmp.Mc2600 = partInfo;
                    } else if (majorClass === 'QX') {

                        tmp.qx = partInfo;
                    } else if (majorClass === 'FZ') {

                        tmp.fz = partInfo;
                    }
                }
                let cf = {
                    id:2,
                    label: '发电机',
                    icon: cfIcon,
                    children:[]
                };
                let dc = {
                    id: 10,
                    label: '电池组',
                    icon: dcIcon,
                    children: []
                };
                let dy = {
                    id:4,
                    icon:
                    dyIcon,
                    label: '-48V直流电源变换器',
                    children: []
                };
                let en = {
                    id: 77,
                    icon: enIcon,
                    label: '环境',
                    children: []
                };
                if (JSON.stringify(tmp.Engine) != "{}") {

                    cf.children.push({
                        icon: cfIcon,
                        label: '发动机（' + tmp.Engine.number + '）',
                        status: tmp.Engine.status
                    });
                }
                if (JSON.stringify(tmp.ElectricBall) != "{}") {

                    cf.children.push({
                        icon: cfIcon,
                        label: '电球（' + tmp.ElectricBall.number + '）',
                        status: tmp.ElectricBall.status
                    });
                }
                if (JSON.stringify(tmp.Controller) != "{}") {

                    cf.children.push({
                        icon: cfIcon,
                        label: '控制器（' + tmp.Controller.number + '）',
                        status: tmp.Controller.status
                    });
                }
                if (JSON.stringify(tmp.Dtu) != "{}") {

                    en.children.push({
                        id: 20,
                        icon: dtuIcon,
                        label: '通讯（' + tmp.Dtu.number + '）',
                        status: tmp.Dtu.status
                    });
                }
                en.children.push({id: 5, icon:dtuIcon, label: 'DIDO'});
                if (JSON.stringify(tmp.SpServer) != "{}") {

                    en.children.push({
                        icon: ckIcon,
                        label: '串口服务器（' + tmp.SpServer.number + '）',
                        status: tmp.SpServer.status
                    });
                }
                if (JSON.stringify(tmp.Ipc) != "{}") {

                    en.children.push({
                        icon: gkIcon,
                        label: '工控机（' + tmp.Ipc.number + '）',
                        status: tmp.Ipc.status
                    });
                }
                if (JSON.stringify(tmp.qx) != "{}") {

                    en.children.push({
                        id: 21,
                        devicePartsId: tmp.qx.id,
                        icon: qxIcon,
                        label: '气象仪（' + tmp.qx.number + '）',
                        status: tmp.qx.status
                    });
                }
                if (JSON.stringify(tmp.fz) != "{}") {

                    en.children.push({
                        id: 22,
                        devicePartsId: tmp.fz.id,
                        icon: fzIcon,
                        label: '辐照仪（' + tmp.fz.number + '）',
                        status: tmp.fz.status
                    });
                }
                for (let i = 0; i < tmp.BatteryPack.length; i++) {

                    let batJosn = tmp.BatteryPack[i];
                    dc.children.push({
                        id: 3,
                        icon: dcIcon,
                        num: batJosn.number,
                        order: batJosn.serialNumber,
                        label: '电池包' + batJosn.serialNumber + '（' + batJosn.number + '）',
                        status: batJosn.status
                    });
                }
                for (let i = 0; i < tmp.PvModule.length; i++) {

                    let pvJosn = tmp.PvModule[i];
                    dy.children.push({
                        icon: gfIcon,
                        label: '光伏模块' + pvJosn.serialNumber + '（' + pvJosn.number + '）',
                        status: pvJosn.status
                    });
                }
                for (let i = 0; i < tmp.RcModule.length; i++) {

                    let rcJosn = tmp.RcModule[i];
                    dy.children.push({
                        icon: zlIcon,
                        label: '整流模块' + rcJosn.serialNumber + '（' + rcJosn.number + '）',
                        status: rcJosn.status
                    });
                }
                // 加载
                this.treeData = [{
                    id: 1,
                    label: '光柴储能设备（' + this.currDeviceNum + '）',
                    icon: root,
                    children: []
                }];
                if (cf.children.length > 0) {

                    this.treeData[0].children.push(cf);
                }
                if (dc.children.length > 0) {

                    this.treeData[0].children.push(dc);
                }
                if (dy.children.length > 0) {

                    this.treeData[0].children.push(dy);
                }
                this.treeData[0].children.push(en);
            }).catch(err => console.error(err));
            // 初始化总览信息页面
            this.overViewVisible = true;
            this.engineVisible = false;
            this.batteryVisible = false;
            this.DCPCVisible = false;
            this.DIDOVisible = false;
            this.CommVisible = false;
            this.meteorographMainVisible = false;
            this.irradiatorMainVisible = false;
            setTimeout(() => {

                this.$refs.overViewPage.queryOverViewInfo(params);
            }, 10);
        },
        // 树形节点点击事件
        handleNodeClick(nodeData) {

            // 先取消发动机控制页面的订阅，避免订阅错乱
            this.$refs.engineViewPage.unSub();

            // 1(光柴储能节点) 2(发电机节点) 3(电池包节点) 4(-48V直流电源变换器) 5DIDO
            if (nodeData.id == 1) {

                this.overViewVisible = true;
                // 这边重新刷新数据
                let params = {

                    id : this.currWholeId,
                    deviceNum: this.currDeviceNum
                };
                this.$refs.overViewPage.queryOverViewInfo(params);
                this.engineVisible = false;
                this.batteryVisible = false;
                this.batteryAllVisible = false;
                this.DCPCVisible = false;
                this.DIDOVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
            } else if (nodeData.id == 2) {

                this.engineVisible = true;
                this.overViewVisible = false;
                this.batteryVisible = false;
                this.batteryAllVisible = false;
                this.DCPCVisible = false;
                this.DIDOVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() =>{

                    this.$refs.engineViewPage.initPage(this.currWholeId, this.currDeviceNum);
                }, 10);
            } else if (nodeData.id == 3){

                this.batteryVisible = true;
                this.batteryAllVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.DCPCVisible = false;
                this.DIDOVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() =>{

                    this.$refs.batteryPackagePage.initBatteryPage(this.currDeviceNum, nodeData.num, nodeData.order);
                }, 10);
            } else if (nodeData.id == 4) {

                this.DCPCVisible = true;
                this.batteryVisible = false;
                this.batteryAllVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.DIDOVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() =>{

                    this.$refs.DCPCPage.initDCPCPage(this.currDeviceNum);
                }, 10);
            } else if (nodeData.id == 5) {

                this.DIDOVisible = true;
                this.DCPCVisible = false;
                this.batteryVisible = false;
                this.batteryAllVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() => {

                    this.$refs.DIDOInfoPage.initDIDOPage(this.currDeviceNum);
                })
            } else if (nodeData.id == 10) {

                // 10个电池组信息
                this.batteryAllVisible = true;
                this.DIDOVisible = false;
                this.DCPCVisible = false;
                this.batteryVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.CommVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() => {

                    this.$refs.batteryPackageAllPage.initBatteryAllPage(this.currDeviceNum, nodeData.children);
                })
            } else if (nodeData.id == 20) {

                this.CommVisible = true;
                this.batteryAllVisible = false;
                this.DIDOVisible = false;
                this.DCPCVisible = false;
                this.batteryVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = false;
                setTimeout(() => {
                    this.$refs.communicationMainPage.initPage(this.currDeviceNum);
                }, 10);
            } else if (nodeData.id == 21) {

                this.CommVisible = false;
                this.batteryAllVisible = false;
                this.DIDOVisible = false;
                this.DCPCVisible = false;
                this.batteryVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.meteorographMainVisible = true;
                this.irradiatorMainVisible = false;
                setTimeout(() => {

                    this.$refs.meteorographMainPage.initPage(this.currDeviceNum, nodeData.devicePartsId);
                }, 10);
            } else if (nodeData.id == 22) {

                this.CommVisible = false;
                this.batteryAllVisible = false;
                this.DIDOVisible = false;
                this.DCPCVisible = false;
                this.batteryVisible = false;
                this.overViewVisible = false;
                this.engineVisible = false;
                this.meteorographMainVisible = false;
                this.irradiatorMainVisible = true;
                setTimeout(() => {

                    this.$refs.irradiatorMainPage.initPage(this.currDeviceNum, nodeData.devicePartsId);
                }, 10);
            }
        },
        // 树形结构内容渲染
        renderContent: function (h, {node, data, store}) {
            if (data.status == "1") {
                let statusIcon = require('../../../assets/icon/operation.png');
                return (<span><img src={statusIcon} width="14" height="14"/><span style="padding-left: 4px;color:red">{node.label}</span></span>);
            } else {

                return (<span><img src={data.icon} width="14" height="14"/><span style="padding-left: 4px;">{node.label}</span></span>);
            }
        },
    }
}
</script>

<style scoped>
</style>