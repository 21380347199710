<template>
    <div v-show="batteryVisible">
        <el-form :model="batteryPackageForm" ref="batteryPackageForm" class="engineForm" :inline="true">
            <el-row style="margin-bottom: 12px;">
                <el-col :span="14" style="height: 650px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">基本信息</legend>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="6">
                                <el-form-item label="电池包："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageNum}}</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="电流："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageForm.batDci}} A</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="6">
                                <el-form-item label="总电压："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageForm.batDcv}} V</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="充放电循环次数："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageForm.batLoopCount}}</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 20px;margin-top: 30px">
                            <el-col :span="6">
                                <el-form-item label="剩余电流百分比："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageForm.batSoc}} %</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="电池健康状态："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>{{this.batteryPackageForm.batSoh}} %</el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </el-col>
                <el-col :span="10" style="height: 650px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">测温点</legend>
                        <el-row style="margin-left: 28px;margin-top: 30px">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="最高温度："></el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item>{{this.batteryPackageForm.batTempMax}} 度</el-form-item>
                                </el-col>
                            </el-row>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="最低温度："></el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item>{{this.batteryPackageForm.batTempMin}} 度</el-form-item>
                                </el-col>
                            </el-row>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="平均温度："></el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item>{{this.batteryPackageForm.batTempAvg}} 度</el-form-item>
                                </el-col>
                            </el-row>
                        </el-row>
                    </fieldset>
                </el-col>
            </el-row>
            <el-row style="text-align: right;margin-top: 23px;">
                <label style="font-weight: bolder">{{this.batteryPackageForm.dtuWorkStatus}} 最后上报时间：</label>
                <label>{{this.batteryPackageForm.reportTime}}</label>
            </el-row>
        </el-form>
    </div>
</template>
<script>
    import {queryBatteryPackageReportData} from "../../../../api/station/deviceInfoApi";
    export default {
        name: "batteryPackageMain",
        props: {
            batteryVisible:{
                type: Boolean,
                default: false
            },
        },
        data() {

            return {

                batteryPackageForm: {

                    batDcv: '0',
                    batDci: '0',
                    batLoopCount: '0',
                    batSoc: '0',
                    batSoh: '0',
                    batTempMax: '0',
                    batTempMin: '0',
                    batTempAvg: '0',
                    reportTime: '无',
                    dtuWorkStatus: ""
                },
                batteryPackageNum: ''
            }
        },
        methods: {

            // 初始化电池包详情页面
            initBatteryPage(deviceNum, num, serialNum) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                this.batteryPackageNum = num;
                let params = {

                    deviceNum: deviceNum,
                    serialNum: serialNum
                };
                queryBatteryPackageReportData(params).then((res) => {

                    if (res.data != null) {

                        this.batteryPackageForm = res.data;
                    } else {

                        this.$message({
                            showClose: true,
                            message: '查询电池数据发生异常！',
                            type: 'error'
                        });
                    }
                }).catch(err => console.error(err));
            }
        }
    }
</script>
<style>

</style>

<style scoped>


</style>
