<template>
    <div v-show="CommVisible">
        <div style="margin-left: 30px;">
            <label>时间：</label>
            <el-date-picker v-model="commDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            <el-button style="margin-left: 10px;" type="primary" @click="queryCommInfo()">查询</el-button>
        </div>
        <div id="commRepo" style="width:95%;height:300px;"></div>
    </div>
</template>

<script>
    import {queryCommunicationDevice} from "../../../../api/station/deviceInfoApi";
    let echarts = require('echarts');
    export default {
        name: "CommunicationMain",
        props: {
            CommVisible: {
                type: Boolean,
                default: false
            }
        },
        data() {

            return {

                deviceNum: "",
                commDate: new Date()
            }
        },
        methods : {

            initPage(deviceNum) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                this.deviceNum = deviceNum;
                this.queryCommInfo();
            },
            queryCommInfo() {

                let params = {

                    deviceNum: this.deviceNum,
                    commDate: this.commDate
                };
                queryCommunicationDevice(params).then((res) => {

                    let info = res.data;
                    let time = [];
                    let value1 = [];
                    for (let key in info) {

                        time.push(key);
                        value1.push(info[key]);
                    }
                    let batteryTempOp =  {
                        title: {
                            text: '上报设备',
                            left:"center"
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter: '{b0}: {c0}上报'
                        },
                        grid: {
                            left: '1%',
                            right: '1%',
                            bottom: '10%',
                            containLabel: true,
                            width: "100%"
                        },
                        xAxis: {
                            type: 'category',
                            data: time
                        },
                        yAxis: {
                            type: 'category'
                        },
                        series: [{

                            data: value1,
                            type: 'line',
                            smooth: true
                        }]
                    };
                    let batteryVol = echarts.init(document.getElementById('commRepo'));
                    batteryVol.setOption(batteryTempOp);
                }).catch(err => console.error(err));
            }
        }
    }
</script>

<style scoped>

</style>
